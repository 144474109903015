<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"/>
            <!--            vandar-modal-->
            <b-modal
                    ref="vandar-modal"
                    title="الحاق آدرس"
                    ok-title="ثبت آدرس"
                    cancel-title="لغو"
                    v-model="vandarModal"
                    @ok.prevent="setAddressToWallet"
            >
                <p>
                    لطفا آدرس کیف پول شبکه {{$coins[addAddressData.coin].persianName}} خود را از حساب بایننس استخراج کرده و در کادر زیر وارد نمایید.
                </p>
                <b-form>
                    <b-form-group class="mt-1" label="آدرس کیف پول بایننس">
                        <b-form-input dir="ltr" v-model="walletAddress" placeholder="آدرس کیف پول بایننس"/>
                    </b-form-group>
                    <b-form-group class="mt-1" label="تگ یا ممو">
                        <b-form-input dir="ltr" v-model="walletTag" placeholder="تگ یا ممو"/>
                    </b-form-group>
                    <b-form-group class="mt-1" label="آدرس کنونی">
                        <b-form-input plaintext dir="ltr" v-model="addAddressData.address" placeholder="---"/>
                    </b-form-group>
                    <b-form-group class="mt-1" label="تگ یا ممو کنونی">
                        <b-form-input plaintext dir="ltr" v-model="addAddressData.memo" placeholder="---"/>
                    </b-form-group>
                </b-form>
            </b-modal>

            <!--header-->
            <div v-if="$havePermission('CREATE_WALLET')" class="demo-spacing-0 d-flex justify-content-between m-1"
                 dir="rtl">
                <!--<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" @click="createWallet()">
                    <feather-icon size="16" icon="DollarSignIcon"/>
                    ساخت کیف پول جدید
                </b-button>-->
            </div>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        class="text-nowrap"
                        style="min-height : 300px"
                >
                    <template #cell(relatedCoin)="data">
                        <b-media vertical-align="center">
                            <template #aside>
                                <b-avatar
                                        size="32"
                                        :src="`/coins/${data.item.coin}.png`"
                                        :variant="`light-info`"
                                        :to="data.item.coin!=='TOMAN' ? { name:  'statistics', params: { id: data.item.coin+'_'+'TOMAN' } } : ''"
                                />
                            </template>
                            <component :is="data.item.coin!=='TOMAN' ? 'b-link' : 'span'"
                                       :to="{ name: 'statistics', params: { id: data.item.coin+'_'+'TOMAN' }}"
                                       class="font-weight-bold d-block text-nowrap"
                            >
                                {{ $coins[data.item.coin].persianName }} <span v-if="data.item.coin==='TETHER'">({{data.item.network}})</span>
                            </component>
                            <small class="text-muted">{{ data.item.coin }}</small>
                        </b-media>
                    </template>

                    <template #cell(withdrawEnable)="data">
                        <b-badge
                                pill
                                :variant="'light-'+statusVariant(data.item.withdrawEnable)"
                                class="text-capitalize"
                        >
                            {{statusLabel(data.item.withdrawEnable)}}
                        </b-badge>
                    </template>

                    <template #cell(depositEnable)="data">
                        <b-badge
                                pill
                                :variant="'light-'+statusVariant(data.item.depositEnable)"
                                class="text-capitalize"
                        >
                            {{statusLabel(data.item.depositEnable)}}
                        </b-badge>
                    </template>

                    <template #cell(action)="data">
                        <template v-if="$havePermission('CREATE_WALLET') || $havePermission('DETAILS_WALLET')">
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>

                                <!--<b-dropdown-item
                                        v-if="$havePermission('CREATE_WALLET') && data.item.coin!=='TOMAN'"
                                        @click="withdraw(data.item)">
                                    <feather-icon icon="UploadIcon"/>
                                    <span class="align-middle ml-50">برداشت از کیف پول</span>
                                </b-dropdown-item>-->

                                <b-dropdown-item v-if="$havePermission('LIST_TRANSACTIONS')"
                                                 :to="{name:history(data.item.coin),query:{relatedCoin : data.item.coin}}">
                                    <feather-icon icon="ClockIcon"/>
                                    <span class="align-middle ml-50">سوابق تراکنش</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('LIST_TRANSACTIONS')"
                                                 @click="setAddress(data.item)"
                                >
                                    <feather-icon icon="UploadIcon"/>
                                    <span class="align-middle ml-50">الحاق آدرس</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>
                </b-table>
            </div>
            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(currentPage,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BPagination,
        BCard,
        // BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BBadge,
        BMedia,
        BAvatar,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BFormTextarea,
        BFormCheckbox,
        BFormCheckboxGroup,
        BInputGroupAppend,
        BButton,
        BOverlay,
        BLink,
        // BRow,
        // BCol,
        // BFormInvalidFeedback,
    } from 'bootstrap-vue'
    import SearchAndFilter from "@/layouts/components/SearchAndFilter";
    // import Cleave from 'vue-cleave-component'
    import vSelect from 'vue-select';
    import NotAllowed from "@/layouts/components/NotAllowed";
    import {statusLabel,statusVariant} from "@/libs/reuseableFunction"

    export default {
        name: 'ExchangeWallets',
        components: {
            NotAllowed,
            SearchAndFilter,
            BCard,
            BTable,
            BDropdown,
            BDropdownItem,
            BBadge,
            BMedia,
            BAvatar,
            BForm,
            BFormGroup,
            BFormInput,
            BInputGroup,
            BFormTextarea,
            BFormCheckbox,
            BFormCheckboxGroup,
            BButton,
            BInputGroupAppend,
            BOverlay,
            BLink,
            vSelect,
            BPagination,
        },
        data: () => ({
            test: 0,
            pageLength: 3,
            dir: false,
            searchTerm: '',
            currentPage: 1,
            perPage: 10,
            rows: 200,
            sortBy: '',
            cryptoName: '',
            cryptoUnit: '',
            walletName: '',
            walletTag:'',
            QRcode: '',
            walletAddress:'',
            vandar: {
                mobile: '',
                password: ''
            },
            withdrawRequest: {
                walletName: '',
                password: '',
                value: '',
                outputAddress: ''
            },
            mnRequest: {
                walletName: '',
                password: '',
                mnemonic: ''
            },
            depositRequest: {
                txid: ''
            },
            addAddressData: {
                walletId: '',
                address: '',
            },
            depositAddress: '',
            isSortDirDesc: '',
            userData: [],
            isActive: false,
            addressModal: false,
            withModal: false,
            dipModal: false,
            mnModal: false,
            feeModal: false,
            accumulateModal: false,
            accumulateWallet: {
                password: '',
                minBalance: '',
                maxBurn: '',
                label: ''
            },
            vandarModal: false,
            totalBalance: '',
            loading: false,
            wallet: {walletName: '', password: '', relatedCoin: 'BITCOIN', addressCount: 1},
            password: '',
            passwordType: 'password',
            secondPasswordType: 'password',
            newWalletModal: false,
            perPageOptions: [5, 10, 20, 50, 100],
            coins: [],
            items: [],
            columns: [
                {
                    label: 'رمز ارز',
                    key: 'relatedCoin',
                    sortable: true,
                    searchType: 'text'
                },
                {
                    label: 'آدرس',
                    key: 'address',
                    sortable: true,
                    searchType: 'text'
                },
                {
                    label: 'حداقل برداشت',
                    key: 'minWithdraw',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'حداکثر برداشت',
                    key: 'maxWithdraw',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'کارمزد برداشت',
                    key: 'feeWithdraw',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'تگ یا ممو',
                    key: 'memo',
                    sortable: true,
                    searchType: 'text'
                },
                {
                    label: 'قابلیت برداشت',
                    key: 'withdrawEnable',
                    sortable: true,
                    searchType: 'select',
                    selectOptions:[
                        {label: 'فعال', value: true},
                        {label: 'غیر فعال', value: false},
                    ]
                },
                {
                    label: 'قابلیت واریز',
                    key: 'depositEnable',
                    sortable: true,
                    searchType: 'select',
                    selectOptions:[
                        {label: 'فعال', value: true},
                        {label: 'غیر فعال', value: false},
                    ]
                },
                {
                    label: 'عملیات',
                    key: 'action'
                },
            ],
            minFee: 0,
        }),
        watch: {
            'wallet.addressCount'(val) {
                val.replace(/([A-Z,a-z])\w+/g, '')
                this.wallet.addressCount = val
            },
            'withdrawRequest.value'() {
                this.withdrawRequest.value = this.$toLocal(this.withdrawRequest.value)
            },
        },
        computed: {
            statusLabel,statusVariant,
            history() {
                return e =>
                    ['ETHEREUM', 'BINANCE_CHAIN_BEP20', 'MATIC', 'CELO', 'ELROND', 'TETHER'].includes(e) ? 'ethereum-history'
                        : ['TRON', 'TETHER'].includes(e) ? 'tron-history'
                        : ['STELLAR', 'RIPPLE'].includes(e) ? 'x-history'
                            : e === 'TOMAN' ? 'toman-transactions'
                                : ['BITCOIN', 'BITCOIN_CASH', 'DOGE_COIN', 'LITE_COIN'].includes(e) ? 'wallet-transactions'
                                    : 'other-transactions'
            },
            allowCreateWallet() {
                /*if (['STELLAR', 'RIPPLE'].includes(this.wallet.coin)) {
                    return this.password === this.wallet.password && this.wallet.password.length > 9
                } else*/
                if (this.wallet.coin !== 'TETHER') {
                    return this.wallet.password && this.password === this.wallet.password && this.wallet.password.length > 9
                } else {
                    return this.wallet.tokenType
                }
            },
            allowWithdraw() {
                if (!this.withdrawRequest.id || !this.withdrawRequest.password || !this.$S2N(this.withdrawRequest.value) || !this.withdrawRequest.outputAddress) {
                    return false
                } else if (['STELLAR', 'RIPPLE'].includes(this.wallet.coin)) {
                    return this.withdrawRequest.tag
                } else if (this.withdrawRequest.coin === 'TETHER') {
                    return this.withdrawRequest.tokenType
                } else {
                    return true
                }
            },
        },
        methods: {
            submit() {
                this.dipModal = false
                this.withModal = false
            },
            setAddress(e){
                this.addAddressData = e
                this.vandarModal = true
            },
            async setAddressToWallet() {
                if(this.walletAddress){
                    const title = `آیا از ثبت آدرس زیر برای شبکه ${this.$coins[this.addAddressData.coin].persianName} خود مطمئن هستید؟`
                    const text = this.walletAddress + '\n' + this.walletTag
                    const res = await this.$error(title,text,'error','ثبت و اعمال آدرس','لغو')
                    console.log(res)
                    if (res.value) {
                        this.state.loading = true
                        await this.$axios.post('/wallets/set-address',{
                            ...this.addAddressData,
                            address: this.walletAddress,
                            addressTag: this.walletTag,
                        })
                        this.$error('آدرس با موفقیت ثبت شد','','success')
                        ++this.state.reload
                    }
                }
            },

            sort(e) {

                let sort = this.$toSnakeCase(e.sortBy)
                // let sort = e.sortBy
                let sorting = e.sortDesc ? 'DESC' : 'ASC'

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        orderBy: sort,
                        sorting: sorting
                    }
                })

                this.getData()

            },

            async getData(page = 1, perPage = this.perPage) {
                this.state.loading = true

                let queryParams = {
                    size: perPage,
                    page: page,
                    ...this.$route.query,
                }

                const res = await this.$axios.get(
                    '/wallets/crypto-networks',
                    {
                        params: queryParams
                    }
                )

                this.state.loading = false
                this.items = res.data.content
                this.currentPage = res.data.number + 1
                this.rows = res.data.totalElements
            },
        },
        created() {
            this.getData()
        }
    }
</script>
<style lang="scss">
    [v-cloak] {
        opacity: 0;
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }
</style>
